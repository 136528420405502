import React, { useEffect, useState, useCallback } from 'react';
import debounce from 'lodash.debounce';

import { Box, TextField, Autocomplete, CircularProgress } from '@mui/material';
import { getDocuments } from '@/actions/document';
import { useConfiguration } from '@/providers/ConfigurationContext';

function InspectionDocumentsField({
  document_type_id,
  value,
  setValue,
  setChanged,
  edit,
}) {
  const configuration = useConfiguration();
  const [query, setQuery] = useState('');
  const [documents, setDocuments] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(value);
  const [page, setPage] = useState(1);

  const fetchDocuments = useCallback(
    (newQuery, newPage) => {
      debounce(async () => {
        setLoading(true);

        try {
          const response = await getDocuments(
            configuration.api,
            document_type_id,
            50,
            (newPage - 1) * 50,
            Boolean(newQuery) ? newQuery : undefined
          );
          setDocuments((prevOptions) =>
            newPage === 1 ? response : [...prevOptions, ...response]
          );
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
        }
      }, 300)();
    },
    [setLoading, configuration.api, document_type_id, setDocuments]
  );

  useEffect(() => {
    if (query.length >= 2) {
      fetchDocuments(query, 1); // Fetch new items for new query
      setPage(1);
    } else {
      setDocuments([]);
    }
  }, [query, fetchDocuments]);

  const handleScroll = (event) => {
    const listboxNode = event.currentTarget;
    if (
      listboxNode.scrollHeight - listboxNode.scrollTop ===
        listboxNode.clientHeight &&
      !loading &&
      query.length >= 2
    ) {
      fetchDocuments(query, page + 1);
      setPage((prevPage) => prevPage + 1);
    }
  };

  const handleChange = (event, newValues) => {
    setChanged();

    setSelected(newValues);
    setValue(newValues);
  };

  return (
    <Box
      sx={{
        width: '100%',
        maxWidth: '600px',
        justifyContent: 'center',
        justifyItems: 'center',
        textAlign: 'justify',
      }}
    >
      <Autocomplete
        id='documents-field'
        // multiple
        options={documents || []}
        loading={loading}
        onInputChange={(event, newInputValue) => setQuery(newInputValue)}
        onClose={() => setDocuments([])} // Optional: clear options on close
        ListboxProps={{
          onScroll: handleScroll,
        }}
        sx={{ width: '100%' }}
        value={selected}
        onChange={handleChange}
        disabled={edit}
        getOptionLabel={(option) => (option ? `${option.name}` : undefined)}
        disableClearable={true}
        renderInput={(params) => (
          <TextField
            {...params}
            required
            label={`Select a Document to Inspect`}
            placeholder={
              !selected ? 'Start typing to load document list...' : ''
            }
            helperText={`Select a Documents to run the Manifest checks on.`}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress color='inherit' size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </Box>
  );
}

export default InspectionDocumentsField;
