import React, { useCallback, useEffect, useState } from 'react';
import {
  Stack,
  Typography,
  Box,
  Link,
  Divider,
  Button,
  LinearProgress,
} from '@mui/material';

import Grid from '@mui/material/Unstable_Grid2';
import Semaphore from '@/util/Semaphore';

import { useConfiguration } from '@/providers/ConfigurationContext';
import DocumentViewDialog from './DocumentViewDialog';
import DocumentControlChecks from './DocumentControlChecks';
import QAChecks from './QAChecks';
import ManifestField from './ManifestField';
import { getDocumentAsset } from '@/actions/documentAsset';
import CHECK_RESULT_STATUSES from './checkResultStatuses';
import { getManifest } from '@/actions/manifest';
import { getManifestChecks, runManifestCheck } from '@/actions/manifestCheck';
import { useDocTypes } from '@/providers/DocTypesContext';
import LoadingMask from '@/components/shared/LoadingMask';
import DocumentUploader from '@/components/shared/DocumentUploader';
import { countTokens } from '@/actions/tokenize';
import ErrorSnackBar from '@/components/shared/ErrorSnackBar';

const MAX_CONCURRENT_CHECKS = 10;

const PASSED = /^PASSED/;
const FAILED = /^FAILED/;

const calculateStatus = (final_result) => {
  if (!final_result?.generated_text) {
    return CHECK_RESULT_STATUSES.UNKNOWN;
  }
  if (final_result.generated_text.match(PASSED)) {
    return CHECK_RESULT_STATUSES.PASSED;
  }
  if (final_result.generated_text.match(FAILED)) {
    return CHECK_RESULT_STATUSES.FAILED;
  }
  return CHECK_RESULT_STATUSES.UNKNOWN;
};

function Evaluator() {
  // const user = useUser();
  const configuration = useConfiguration();
  const doc_types = useDocTypes();

  const [document_type_id, setDocumentTypeId] = useState(null);
  const [doc_content, setDocContent] = useState('');
  const [reference_doc_content, setReferenceDocContent] = useState('');
  const [doc_title, setDocTitle] = useState('');
  const [document_error, setDocumentError] = useState(undefined);
  const [qa_results, setQaResults] = useState(null);
  const [gdp_results, setGdpResults] = useState(null);
  const [docOpen, setDocOpen] = useState(false);
  const [manifest, setManifest] = useState(null);
  const [global_manifest, setGlobalManifest] = useState(null);
  const [global_manifest_checks, setGlobalManifestChecks] = useState(null);
  const [loading_manifest, setLoadingManifest] = useState(false);
  const [gdp_checks, setGdpChecks] = useState(null);
  const [qa_checks, setQAChecks] = useState(null);

  useEffect(() => {
    if (doc_types && !document_type_id) {
      const maxLevel = Math.max(...doc_types.map((doc_type) => doc_type.level));
      const filteredArray = doc_types.filter(
        (doc_type) => doc_type.level === maxLevel
      );
      setDocumentTypeId(filteredArray[0]?.entity_id);
    }
  }, [doc_types, document_type_id, setDocumentTypeId]);

  const getGdpChecks = useCallback(
    (manifest, manifest_checks) => {
      const checks = [];
      if (global_manifest_checks) {
        global_manifest_checks
          .map((check) => {
            return {
              ...check,
              global: true,
              manifest_id: global_manifest.entity_id,
            };
          })
          .sort((a, b) => (a.name > b.name) - (a.name < b.name))
          .forEach((check) => {
            checks.push(check);
          });
      }
      if (manifest_checks) {
        manifest_checks
          .filter((check) => check.section === 'global')
          .map((check) => {
            return {
              ...check,
              manifest_id: manifest.entity_id,
            };
          })
          .sort((a, b) => (a.name > b.name) - (a.name < b.name))
          .forEach((check) => {
            checks.push(check);
          });
      }
      return checks;
    },
    [global_manifest, global_manifest_checks]
  );

  const getQaChecks = useCallback((manifest, manifest_checks) => {
    const checks = [];
    if (manifest_checks) {
      manifest_checks
        .filter((check) => check.section !== 'global')
        .map((check) => {
          return {
            ...check,
            manifest_id: manifest.entity_id,
          };
        })
        .sort((a, b) => {
          if (a.section === b.section) {
            return (a.name > b.name) - (a.name < b.name);
          } else {
            return (a.section > b.section) - (a.section < b.section);
          }
        })
        .forEach((check) => {
          checks.push(check);
        });
    }
    return checks;
  }, []);

  useEffect(() => {
    if (!global_manifest) {
      getManifest(configuration.api, 'global')
        .then((data) => {
          setGlobalManifest(data);
        })
        .catch((error) => console.log(error));
    }
  }, [global_manifest, configuration.api, setGlobalManifest]);

  useEffect(() => {
    if (global_manifest && !global_manifest_checks) {
      getManifestChecks(configuration.api, global_manifest.entity_id)
        .then((data) => {
          setGlobalManifestChecks(data);
        })
        .catch((error) => console.log(error));
    }
  }, [
    global_manifest,
    global_manifest_checks,
    configuration.api,
    setGlobalManifestChecks,
  ]);

  const runCheck = useCallback(
    async (doc_check) => {
      const response = await runManifestCheck(
        configuration.api,
        doc_check.manifest_id,
        doc_check.entity_id,
        doc_content,
        reference_doc_content
      );

      const reader = response.body.getReader();
      let line = '',
        finish_reason,
        answer = '';

      try {
        while (true) {
          const { done, value } = await reader.read();
          line += new TextDecoder().decode(value);
          if (line.endsWith('\n')) {
            const lines = line
              .trim()
              .split('\n')
              .filter((line) => line);
            for (let i = 0; i < lines.length; i++) {
              const data = lines[i].substring(5).trim();
              if (data === '[DONE]') {
                continue;
              } else {
                const result = JSON.parse(data);
                if (result?.choices) {
                  if (result?.choices[0]?.finish_reason) {
                    finish_reason = result?.choices[0]?.finish_reason;
                  }
                  if (
                    !configuration.model.llm.eos_token.includes(
                      result?.choices[0]?.delta?.content
                    )
                  ) {
                    answer += result?.choices[0]?.delta?.content;
                  }
                } else {
                  if (!result.generated_text) {
                    answer += result.token.text;
                  }
                }
              }
            }
            line = '';
          }
          if (done) {
            break;
          }
        }
      } catch (error) {
        console.log(error);
        throw error;
      }
      const final_status = calculateStatus({
        generated_text: answer,
        finish_reason,
      });
      return { answer, final_status };
    },
    [
      configuration.api,
      configuration.model.llm.eos_token,
      doc_content,
      reference_doc_content,
    ]
  );

  const handleUpload = useCallback(
    async (files) => {
      if (files[0]) {
        const tokens = await countTokens(configuration.api, files[0].contents);
        if (tokens > configuration.model.llm.max_input_length - 500) {
          setDocumentError('File is too large.  Upload a smaller document');
        } else {
          setDocContent(files[0].contents);
          setDocTitle(files[0].name);
        }
      }
    },
    [
      configuration.api,
      configuration.model,
      setDocContent,
      setDocTitle,
      setDocumentError,
    ]
  );

  const handleRunQAChecks = useCallback(async () => {
    const doc_checks = qa_checks
      .filter((check) => check)
      .map((check) => {
        return { ...check, status: 'pending' };
      });

    setQaResults(doc_checks);
    const semaphore = new Semaphore(MAX_CONCURRENT_CHECKS);

    await Promise.all(
      doc_checks.map(async (doc_check) => {
        await semaphore.run(async () => {
          setQaResults((prevState) => {
            const index = prevState.findIndex(
              (cur_check) => cur_check.entity_id === doc_check.entity_id
            );
            const new_check = { ...prevState[index], status: 'running' };
            const new_state = [...prevState];
            new_state[index] = new_check;
            return new_state;
          });
          const result = await runCheck(doc_check);
          setQaResults((prevState) => {
            const index = prevState.findIndex(
              (cur_check) => cur_check.entity_id === doc_check.entity_id
            );
            const new_check = {
              ...prevState[index],
              status: result.final_status,
              result: result.answer,
            };
            const new_state = [...prevState];
            new_state[index] = new_check;
            return new_state;
          });
        });
      })
    );
  }, [runCheck, setQaResults, qa_checks]);

  const handleRunGDPChecks = useCallback(async () => {
    const doc_checks = gdp_checks
      .filter((check) => check)
      .map((check) => {
        return { ...check, status: 'pending' };
      });

    setGdpResults(doc_checks);
    const semaphore = new Semaphore(MAX_CONCURRENT_CHECKS);

    await Promise.all(
      doc_checks.map(async (doc_check) => {
        await semaphore.run(async () => {
          setGdpResults((prevState) => {
            const index = prevState.findIndex(
              (cur_check) => cur_check.entity_id === doc_check.entity_id
            );
            const new_check = { ...prevState[index], status: 'running' };
            const new_state = [...prevState];
            new_state[index] = new_check;
            return new_state;
          });
          const result = await runCheck(doc_check);
          setGdpResults((prevState) => {
            const index = prevState.findIndex(
              (cur_check) => cur_check.entity_id === doc_check.entity_id
            );
            const new_check = {
              ...prevState[index],
              status: result.final_status,
              result: result.answer,
            };
            const new_state = [...prevState];
            new_state[index] = new_check;
            return new_state;
          });
        });
      })
    );
  }, [runCheck, setGdpResults, gdp_checks]);

  const handleSelectManifest = useCallback(
    async (manifest) => {
      setLoadingManifest(true);
      setManifest(manifest);
      console.log(manifest);

      const [full_manifest, manifest_checks] = await Promise.all([
        getManifest(configuration.api, manifest.entity_id),
        getManifestChecks(configuration.api, manifest.entity_id),
      ]);
      const gdp_checks = getGdpChecks(manifest, manifest_checks);
      setGdpChecks(gdp_checks);
      const qa_checks = getQaChecks(manifest, manifest_checks);
      setQAChecks(qa_checks);
      if (full_manifest.reference_document) {
        const reference_doc_content = await getDocumentAsset(
          configuration.api,
          full_manifest.reference_document.entity_id,
          full_manifest.reference_document.content_id
        );
        setReferenceDocContent(reference_doc_content);
      }

      setLoadingManifest(false);
    },
    [
      setManifest,
      configuration.api,
      setGdpChecks,
      setQAChecks,
      getGdpChecks,
      getQaChecks,
    ]
  );

  if (!doc_types) {
    return <LoadingMask />;
  }

  return (
    <Box paddingX={'30px'} paddingY={'10px'}>
      <Grid container spacing={2}>
        <Grid lg={12} md={12} xs={12}>
          <Stack
            direction={'row'}
            justifyContent={'center'}
            spacing={2}
            alignItems={'center'}
          >
            <Typography variant='h3'>
              Quartermaster Document Evaluator
            </Typography>
          </Stack>
        </Grid>
        <Grid lg={12} md={12} xs={12}>
          <Typography variant='h4' textAlign={'center'}>
            Ensuring Document Compliance and Precision
          </Typography>
        </Grid>

        <Grid lg={12} md={12} xs={12}>
          <Divider />
        </Grid>

        {!Boolean(doc_content) ? (
          <Grid lg={12} md={12} xs={12} textAlign={'center'}>
            <Typography variant='h5'>
              Submit Document for Quartermaster Evaluation
            </Typography>
          </Grid>
        ) : undefined}

        <Grid lg={12} md={12} xs={12}>
          <DocumentUploader max_docs={1} setDocuments={handleUpload} />
        </Grid>
        {Boolean(doc_content) ? (
          <Grid lg={12} md={12} xs={12} textAlign={'center'}>
            <Typography variant='h6' sx={{ wordBreak: 'break-all' }}>
              Uploaded <b>{doc_title}</b>
            </Typography>
            <Link
              component='button'
              variant='h6'
              onClick={() => {
                setDocOpen(true);
              }}
            >
              <Typography variant='h6'>View Contents</Typography>
            </Link>
            <DocumentViewDialog
              title={doc_title}
              contents={doc_content}
              open={docOpen}
              handleClose={() => setDocOpen(false)}
            />
          </Grid>
        ) : undefined}
        <Grid
          lg={12}
          md={12}
          xs={12}
          container
          spacing={2}
          style={{ padding: '15px' }}
        >
          {Boolean(doc_content) ? (
            <>
              {/* <Grid lg={12} md={12} xs={12}>
                <Markdown remarkPlugins={[remarkGfm]}>{doc_content}</Markdown>
              </Grid> */}
              <Grid lg={12} md={12} xs={12}>
                <Box
                  sx={{
                    width: '100%',
                    justifyContent: 'center',
                    textAlign: 'center',
                    justifyItems: 'center',
                  }}
                >
                  <ManifestField
                    document_type_id={document_type_id}
                    value={manifest}
                    setValue={handleSelectManifest}
                  />
                </Box>
              </Grid>
            </>
          ) : undefined}

          {loading_manifest ? (
            <Grid lg={12} md={12} xs={12}>
              <LinearProgress />
            </Grid>
          ) : undefined}
          {Boolean(qa_checks) ? (
            <>
              <Grid lg={12} md={12} xs={12}>
                <Divider />
              </Grid>
              <Grid lg={12} md={12} xs={12} textAlign={'center'}>
                <Typography variant='h5'>Document Control Insights</Typography>
              </Grid>
              {gdp_results ? (
                <Grid lg={12} md={12} xs={12}>
                  <DocumentControlChecks
                    manifest={manifest}
                    doc_content={doc_content}
                    reference_doc_content={reference_doc_content}
                    doc_checks={gdp_results}
                  />
                </Grid>
              ) : (
                <Grid lg={12} md={12} xs={12}>
                  <Button onClick={handleRunGDPChecks} variant='outlined'>
                    Run Document Control Engine
                  </Button>
                </Grid>
              )}
              <Grid lg={12} md={12} xs={12}>
                <Typography variant='caption' sx={{ margin: '10px' }}>
                  <b>Disclaimer:</b> The generated output is for assistance only
                  and should be reviewed by subject matter experts before being
                  used in any product or decision.
                </Typography>
              </Grid>
              <Grid lg={12} md={12} xs={12}>
                <Divider />
              </Grid>
              <Grid lg={12} md={12} xs={12} textAlign={'center'}>
                <Typography variant='h5'>Quality Assurance Insights</Typography>
              </Grid>
              {qa_results ? (
                <Grid lg={12} md={12} xs={12}>
                  <QAChecks
                    manifest={manifest}
                    doc_content={doc_content}
                    reference_doc_content={reference_doc_content}
                    doc_checks={qa_results}
                  />
                </Grid>
              ) : (
                <Grid lg={12} md={12} xs={12}>
                  <Button onClick={handleRunQAChecks} variant='outlined'>
                    Run Quality Assurance Engine
                  </Button>
                </Grid>
              )}
              <Grid lg={12} md={12} xs={12}>
                <Typography variant='caption' sx={{ margin: '10px' }}>
                  <b>Disclaimer:</b> The generated output is for assistance only
                  and should be reviewed by subject matter experts before being
                  used in any product or decision.
                </Typography>
              </Grid>
            </>
          ) : undefined}
        </Grid>
      </Grid>
      <ErrorSnackBar
        message={document_error}
        onClear={() => {
          setDocContent('');
          setDocumentError(undefined);
        }}
      />
    </Box>
  );
}

export default Evaluator;
