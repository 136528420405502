import React, { useCallback, useEffect, useState } from 'react';
import {
  Stack,
  Typography,
  Box,
  Divider,
  Button,
  LinearProgress,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import Semaphore from '@/util/Semaphore';

// import { useUser } from '@/providers/UserContext';
import { useConfiguration } from '@/providers/ConfigurationContext';

import QAChecks from './QAChecks';

import { getDocumentAsset } from '@/actions/documentAsset';
import CHECK_RESULT_STATUSES from './checkResultStatuses';
import { getManifestChecks, runManifestCheck } from '@/actions/manifestCheck';
import { getManifest } from '@/actions/manifest';
import { useDocTypes } from '@/providers/DocTypesContext';
import LoadingMask from '@/components/shared/LoadingMask';
import InspectionDocumentTypeField from './InspectionDocumentTypeField';
import InspectionManifestsField from './InspectionManifestsField';
import InspectionDocumentsField from './InspectionDocumentsField';

const MAX_CONCURRENT_CHECKS = 10;

const PASSED = /^PASSED/;
const FAILED = /^FAILED/;

const calculateStatus = (final_result) => {
  if (!final_result?.generated_text) {
    return CHECK_RESULT_STATUSES.UNKNOWN;
  }
  if (final_result.generated_text.match(PASSED)) {
    return CHECK_RESULT_STATUSES.PASSED;
  }
  if (final_result.generated_text.match(FAILED)) {
    return CHECK_RESULT_STATUSES.FAILED;
  }
  return CHECK_RESULT_STATUSES.UNKNOWN;
};

function InternalAudit() {
  // const user = useUser();
  const configuration = useConfiguration();
  const doc_types = useDocTypes();

  const [doc_content, setDocContent] = useState('');
  const [reference_doc_content, setReferenceDocContent] = useState('');
  const [qa_results, setQaResults] = useState(null);
  const [loading_manifest, setLoadingManifest] = useState(false);
  const [qa_checks, setQAChecks] = useState(null);
  const [document_type, setDocumentType] = useState();
  const [manifests, setManifests] = useState(null);
  const [documents, setDocuments] = useState(null);

  const getChecks = useCallback((manifest, manifest_checks) => {
    const checks = [];
    if (manifest_checks) {
      manifest_checks

        .map((check) => {
          return {
            ...check,
            manifest_id: manifest.entity_id,
          };
        })
        .sort((a, b) => {
          if (a.section === b.section) {
            return (a.name > b.name) - (a.name < b.name);
          } else {
            return (a.section > b.section) - (a.section < b.section);
          }
        })
        .forEach((check) => {
          checks.push(check);
        });
    }
    return checks;
  }, []);

  const runCheck = useCallback(
    async (doc_check) => {
      const response = await runManifestCheck(
        configuration.api,
        doc_check.manifest_id,
        doc_check.entity_id,
        doc_content,
        reference_doc_content
      );

      const reader = response.body.getReader();
      let line = '',
        finish_reason,
        answer = '';

      try {
        while (true) {
          const { done, value } = await reader.read();
          line += new TextDecoder().decode(value);
          if (line.endsWith('\n')) {
            const lines = line
              .trim()
              .split('\n')
              .filter((line) => line);
            for (let i = 0; i < lines.length; i++) {
              const data = lines[i].substring(5).trim();
              if (data === '[DONE]') {
                continue;
              } else {
                const result = JSON.parse(data);
                if (result?.choices) {
                  if (result?.choices[0]?.finish_reason) {
                    finish_reason = result?.choices[0]?.finish_reason;
                  }
                  if (
                    !configuration.model.llm.eos_token.includes(
                      result?.choices[0]?.delta?.content
                    )
                  ) {
                    answer += result?.choices[0]?.delta?.content;
                  }
                } else {
                  if (!result.generated_text) {
                    answer += result.token.text;
                  }
                }
              }
            }
            line = '';
          }
          if (done) {
            break;
          }
        }
      } catch (error) {
        console.log(error);
        throw error;
      }
      const final_status = calculateStatus({
        generated_text: answer,
        finish_reason,
      });
      return { answer, final_status };
    },
    [
      configuration.api,
      configuration.model.llm.eos_token,
      doc_content,
      reference_doc_content,
    ]
  );

  const handleRunQAChecks = useCallback(async () => {
    const doc_checks = qa_checks
      .filter((check) => check)
      .map((check) => {
        return { ...check, status: 'pending' };
      });

    setQaResults(doc_checks);
    const semaphore = new Semaphore(MAX_CONCURRENT_CHECKS);

    await Promise.all(
      doc_checks.map(async (doc_check) => {
        await semaphore.run(async () => {
          setQaResults((prevState) => {
            const index = prevState.findIndex(
              (cur_check) => cur_check.entity_id === doc_check.entity_id
            );
            const new_check = { ...prevState[index], status: 'running' };
            const new_state = [...prevState];
            new_state[index] = new_check;
            return new_state;
          });
          const result = await runCheck(doc_check);
          setQaResults((prevState) => {
            const index = prevState.findIndex(
              (cur_check) => cur_check.entity_id === doc_check.entity_id
            );
            const new_check = {
              ...prevState[index],
              status: result.final_status,
              result: result.answer,
            };
            const new_state = [...prevState];
            new_state[index] = new_check;
            return new_state;
          });
        });
      })
    );
  }, [runCheck, setQaResults, qa_checks]);

  useEffect(() => {
    (async () => {
      if (manifests) {
        setLoadingManifest(true);
        const manifest = manifests;

        const [full_manifest, manifest_checks] = await Promise.all([
          getManifest(configuration.api, manifest.entity_id),
          getManifestChecks(configuration.api, manifest.entity_id),
        ]);

        const checks = getChecks(manifest, manifest_checks);
        setQAChecks(checks);
        if (full_manifest.reference_document) {
          const reference_doc_content = await getDocumentAsset(
            configuration.api,
            full_manifest.reference_document.entity_id,
            full_manifest.reference_document.content_id
          );
          setReferenceDocContent(reference_doc_content);
        }

        setLoadingManifest(false);
      } else {
        setReferenceDocContent(null);
        setQAChecks(null);
      }
    })();
  }, [manifests, configuration.api, getChecks, setQAChecks]);

  useEffect(() => {
    (async () => {
      if (documents) {
        const document = documents;

        const doc_content = await getDocumentAsset(
          configuration.api,
          document.entity_id,
          document.content_id
        );
        setDocContent(doc_content);
      } else {
        setDocContent(null);
      }
    })();
  }, [documents, configuration.api, setDocContent]);

  if (!doc_types) {
    return <LoadingMask />;
  }

  return (
    <Box paddingX={'30px'} paddingY={'10px'}>
      <Grid container spacing={2}>
        <Grid lg={12} md={12} xs={12}>
          <Stack
            direction={'row'}
            justifyContent={'center'}
            spacing={2}
            alignItems={'center'}
          >
            <Typography variant='h3'>Quartermaster Internal Audit</Typography>
          </Stack>
        </Grid>
        <Grid lg={12} md={12} xs={12}>
          <Typography variant='h4' textAlign={'center'}>
            Ensuring Procedure and Regulatory Compliance
          </Typography>
        </Grid>

        <Grid lg={12} md={12} xs={12}>
          <Divider />
        </Grid>

        <Grid lg={12} md={12} xs={12}>
          <InspectionDocumentTypeField
            value={document_type}
            setValue={setDocumentType}
            setChanged={() => {}}
          />
        </Grid>
        {document_type ? (
          <Grid lg={12} md={12} xs={12}>
            <InspectionDocumentsField
              value={documents}
              setValue={setDocuments}
              document_type_id={document_type.entity_id}
              setChanged={() => {}}
            />
          </Grid>
        ) : undefined}
        {document_type ? (
          <Grid lg={12} md={12} xs={12}>
            <InspectionManifestsField
              value={manifests}
              setValue={setManifests}
              document_type_id={document_type.entity_id}
              setChanged={() => {}}
            />
          </Grid>
        ) : undefined}

        {loading_manifest ? (
          <Grid lg={12} md={12} xs={12}>
            <LinearProgress />
          </Grid>
        ) : undefined}
        {Boolean(qa_checks) ? (
          <>
            <Grid lg={12} md={12} xs={12}>
              <Divider />
            </Grid>
            <Grid lg={12} md={12} xs={12} textAlign={'center'}>
              <Typography variant='h5'>
                Standards Compliance Insights
              </Typography>
            </Grid>
            {qa_results ? (
              <Grid lg={12} md={12} xs={12}>
                <QAChecks
                  manifest={manifests}
                  doc_content={doc_content}
                  reference_doc_content={reference_doc_content}
                  doc_checks={qa_results}
                />
              </Grid>
            ) : (
              <Grid lg={12} md={12} xs={12}>
                <Button onClick={handleRunQAChecks} variant='outlined'>
                  Run Standards Compliance Engine
                </Button>
              </Grid>
            )}
            <Grid lg={12} md={12} xs={12}>
              <Typography variant='caption' sx={{ margin: '10px' }}>
                <b>Disclaimer:</b> The generated output is for assistance only
                and should be reviewed by subject matter experts before being
                used in any product or decision.
              </Typography>
            </Grid>
          </>
        ) : undefined}
      </Grid>
    </Box>
  );
}

export default InternalAudit;
